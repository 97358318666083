import request from "@/utils/request";
import Export from "@/utils/export";

// 门店商品列表
export function getMallGoodsList(data) {
  return request("post", "/apm/shop/goods/page", data);
}

// 新增门店商品列表
export function getAddGoodsList(data) {
  return request("post", "/apm/merchant/goods/listByShopId", data);
}

// 保存新增商品
export function getAddGoodSave(data) {
  return request("post", "/apm/shop/goods/batch/create", data);
}

// 编辑新增商品
export function getAddGoodEdit(data) {
  return request("post", "/apm/shop/goods/updateGoods", data);
}

// 编辑新增商品
export function getInventoryEdit(data) {
  return request("post", "/apm/shop/goods/inventory/update", data);
}

// 点单商品分类列表
export function getGoodsTypeList(data) {
  return request("post", "/apm/goods/type/list", data);
}

// 新增商品分类
export function getGoodsTypeAdd(data) {
  return request("post", "/apm/goods/type/create", data);
}

// 编辑商品分类
export function getGoodsTypeEdit(data) {
  return request("post", "/apm/goods/type/update", data);
}

// 删除商品分类
export function getGoodsTypeDel(data) {
  return request("post", "/apm/goods/type/del", data);
}

// 点单查询商品列表
export function getShopGoodsList(data) {
  return request("post", "/apm/shop/goods/list", data);
}

// 点单商品列表
export function getMallGoodsShop(data) {
  return request("post", "/apm/dispatch/goods/page", data);
}

// 新增点单商品
export function getGoodsShopAdd(data) {
  return request("post", "/apm/dispatch/goods/create", data);
}

// 编辑点单商品
export function getGoodsShopEdit(data) {
  return request("post", "/apm/dispatch/goods/update", data);
}

// 上下架点单商品
export function getGoodsShopUp(data) {
  return request("post", "/apm/dispatch/goods/upAndDown", data);
}

// 补货点单商品
export function getGoodsShopReplenish(data) {
  return request("post", "/apm/dispatch/goods/replenishment", data);
}

// 售卖柜列表
export function getCabinetList(data) {
  return request("post", "/apm/goods/shelves/pageList", data);
}

// 修改售卖柜设备信息
export function getCabinetDeviceEdit(data) {
  return request("post", "/apm/goods/shelves/update/device", data);
}

// 查询售卖柜设备详情
export function getCabinetItem(data) {
  return request("post", "/apm/goods/shelves/goodsList", data);
}

// 售卖柜商品保存修改
export function getCabinetEdit(data) {
  return request("post", "/apm/goods/shelves/update", data);
}

// 售卖柜商品解绑
export function getCabinetDel(data) {
  return request("post", "/apm/goods/shelves/del", data);
}

// 售卖柜商品添加
export function getCabinetAdd(data) {
  return request("post", "/apm/goods/shelves/create", data);
}

// 售卖柜商品售罄
export function getCabinetSellOut(data) {
  return request("post", "/apm/goods/shelves/setToSell", data);
}

// 售卖柜打开货柜
export function getCabinetOpen(data) {
  return request("post", "/apm/goods/shelves/open", data);
}

// 售卖柜商品全部补货
export function getCabinetAllAdd(data) {
  return request("post", "/apm/goods/shelves/allReplenishment", data);
}

// 商品订单管理列表
export function getOrderList(data) {
  return request("post", "/apm/goods/order/page", data);
}

// 商品订单退款详情
export function getOrderInfo(data) {
  return request("post", "/apm/goods/order/info", data);
}

// 商品订单退款保存
export function getOrderRefund(data) {
  return request("post", "/apm/goods/order/refund", data);
}

// 商品订单操作记录
export function getOrderOperateLog(data) {
  return request("post", "/apm/goods/order/operateLog", data);
}

// 导出商品订单详情
export function getListExport(data, title) {
  return Export("post", "/apm/goods/order/export", data, title);
}

// 派送订单详情
export function getMallWorkOrder(data) {
  return request(
    "get",
    "/apm/dispatchForm/getDispatchFormInfo?dispatchFormId=" + data.formId
  );
}

// 取消订单
export function goCancelOrder(data) {
  return request("post", "/apm/goods/order/cancel", data);
}


// 处理售卖柜二维码
export function goDealGoodsShelves(data) {
  return request("post", "/apm/goods/shelves/deal", data);
}

// 获取售卖柜二维码进度
export function getGoodsShelvesProgress(data) {
  return request("get", "/apm/goods/shelves/progress", data);
}

// 处理售卖柜二维码
export function goDownloadGoodsShelves(data) {
  return request("get", "/apm/goods/shelves/download", data);
}

// 根据服务内容查询点单商品列表
export function getGoodsListByServiceId(data) {
  return request("post", "/apm/goods/guide/goodsListByServiceId", data);
}

// 查询推荐商品列表
export function getRecommendList(data) {
  return request("post", "/apm/goods/guide/page", data);
}

// 添加推荐商品
export function goAddRecommendGoods(data) {
  return request("post", "/apm/goods/guide/create", data);
}

// 置顶推荐商品
export function goTopRecommendGoods(data) {
  return request("post", "/apm/goods/guide/top", data);
}

// 移除推荐商品
export function goRemoveRecommendGoods(data) {
  return request("post", "/apm/goods/guide/removeById", data);
}

// 推销码导出
export function goExportQrCode(data,name,isZip) {
  return Export("post", "/apm/dispatch/goods/dispatchGoodsMiniCode", data,name,isZip);
}

// 推销码导出检查
export function goCheckExportQrCode(data) {
  return request("post", "/apm/dispatch/goods/export/check", data);
}