<template>
  <div class="mall-goods">
    <!-- 查詢 -->
    <section class="table-search">
      <div class="search-L">
        <el-input v-model="tableParams.kw" placeholder="请输入商品名称搜索" @input="kwChange" clearable class="mr15" />
      </div>
      <div class="search-R">
        <el-button type="primary" @click="onAdd()">新增商品</el-button>
      </div>
    </section>
    <!-- 表格 -->
    <el-table :data="tableData" border stripe :header-cell-style="{
      background: '#FAFAFA', color: '#000000'
    }">
      <el-table-column label="商品缩略图" align="center">
        <template slot-scope="scope">
          <el-image style="width: .28rem; height: .28rem" :src="scope.row.imageUrl"
            :preview-src-list="[scope.row.imageUrl]">
          </el-image>
        </template>
      </el-table-column>
      <el-table-column label="商品名称" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.goodsName || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="商品库存" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.inventory || "0" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="实际销量 / 虚拟销量" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.salesVolume || "0" }}</span>
          <span>&nbsp;/&nbsp;</span>
          <span>{{ scope.row.salesVolumeV || "0" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="销售价格 / 划线价格" align="center">
        <template slot-scope="scope">
          <span>￥{{ scope.row.salesPrice || "0" }}</span>
          <span>&nbsp;/&nbsp;</span>
          <del>￥{{ scope.row.originalPrice || "0" }}</del>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="text" size="medium" @click.stop="onEdit(scope.row)">编辑</el-button>
          <el-button type="text" size="medium" @click.stop="onInventory(scope.row)">进损</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <section class="table-footer">
      <el-pagination layout="total,sizes, prev, pager, next,jumper" background :total="total"
        :page-size="tableParams.pageSize" @size-change="onSizeChange" :page-sizes="[10, 20, 50, 100]"
        :current-page.sync="currentPage" @current-change="onCurrentPage">
      </el-pagination>
    </section>
    <!-- 新增商品弹框 -->
    <el-dialog title="新增商品" :visible.sync="addShow" :close-on-click-modal="false" append-to-body width="40%">
      <el-form :model="addParams" :rules="rules" ref="form-add" v-if="addGoodsList.length > 0">
        <section class="form-main">
          <el-checkbox-group v-model="addParams.merchantGoodsIds">
            <ul class="goods-ul">
              <li class="goods-li" v-for="item in addGoodsList" :key="item.goodsId">
                <el-checkbox class="goods-checkbox" :label="item.goodsId"></el-checkbox>
                <el-image class="goods-img" style="width: 1.5rem; height: 1rem" :src="item.imageUrl">
                </el-image>
                <p class="goods-p">{{ item.goodsName }}</p>
              </li>
            </ul>
          </el-checkbox-group>
        </section>
        <section class="form-footer">
          <el-button type="primary" @click="onAddSubmit('form-add')">保存</el-button>
        </section>
      </el-form>
      <el-empty v-else description="暂无可新增商品，请在商户商品管理中新增商品"></el-empty>
    </el-dialog>
    <!-- 编辑弹框 -->
    <el-dialog title="编辑商品" :visible.sync="editShow" :close-on-click-modal="false" append-to-body width="30%">
      <el-form :model="params" :rules="rules" ref="form-ref" label-width="100px">
        <section class="form-main">
          <el-form-item label="销售价格" prop="salesPrice">
            <el-input v-model.trim="params.salesPrice" autocomplete="off" maxlength="5" placeholder="请填写销售价格"
              oninput="value=value.replace(/[^\d.]/g,'')" clearable>
              <template slot="prepend">￥</template>
            </el-input>
          </el-form-item>
          <el-form-item label="划线价格" prop="originalPrice">
            <el-input v-model.trim="params.originalPrice" autocomplete="off" maxlength="5" placeholder="请填写划线价格"
              oninput="value=value.replace(/[^\d.]/g,'')" clearable>
              <template slot="prepend">￥</template>
            </el-input>
          </el-form-item>
          <el-form-item label="虚拟销量" prop="salesVolumeV">
            <el-input v-model.trim="params.salesVolumeV" autocomplete="off" maxlength="5" placeholder="请填写虚拟销量"
              oninput="value=value.replace(/[^\d]/g,'')" clearable>
            </el-input>
            <p>*销量=实际销量+虚拟销量</p>
          </el-form-item>
          <el-form-item label="实际销量" prop="salesVolume">
            <el-input v-model.trim="params.salesVolume" autocomplete="off" maxlength="5" placeholder="请填写实际销量"
              oninput="value=value.replace(/[^\d]/g,'')" disabled>
            </el-input>
          </el-form-item>
        </section>
        <section class="form-footer">
          <el-button type="primary" @click="onSubmit('form-ref')">保存</el-button>
        </section>
      </el-form>
    </el-dialog>
    <!-- 进销损弹框 -->
    <el-dialog title="进损处理" :visible.sync="inventoryShow" :close-on-click-modal="false" append-to-body width="30%">
      <el-form :model="inventoryParams" :rules="rules" ref="form-inventory" label-width="100px">
        <section class="form-main">
          <el-form-item label="选择类型" prop="inventoryType">
            <el-radio-group v-model="inventoryParams.inventoryType" @change="change()">
              <el-radio :label="1">进货</el-radio>
              <!-- <el-radio :label="2">销货</el-radio> -->
              <el-radio :label="3">损耗</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item :label="countTitle" prop="inventory">
            <el-input v-model.trim="inventoryParams.inventory" autocomplete="off" maxlength="5" placeholder="请填写进销损数量"
              oninput="value=value.replace(/[^\d]/g,'')" clearable>
            </el-input>
          </el-form-item>
          <el-form-item label="当前库存" prop="inventoryType">
            {{ inventoryParams.inventoryNow }}
          </el-form-item>
        </section>
        <section class="form-footer">
          <el-button type="primary" @click="onInventorySubmit('form-inventory')">保存</el-button>
        </section>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  getMallGoodsList,
  getAddGoodsList,
  getAddGoodSave,
  getAddGoodEdit,
  getInventoryEdit,
} from "@/api/mall/mall";
import { rules } from "@/db/rules";
export default {
  data() {
    return {
      // table表格
      currentPage: 1, // 当前页
      total: 0, // 总条数  
      tableData: [],//表格数据
      tableParams: {
        page: 1,
        pageSize: 10,
        kw: "",//查询关键字
        merchantId: "",//商户ID
        shopId: "",//店铺ID
      },
      // 添加商品
      addShow: false,
      addGoodsList: [],
      rules,
      addParams: {
        merchantGoodsIds: [],
      },
      // 编辑商品
      editShow: false,
      params: {
        originalPrice: "",
        salesPrice: "",
        salesVolumeV: "",
      },
      // 进损商品
      inventoryShow: false,
      inventoryParams: {
        inventoryType: 1,
        inventory: "",
        inventoryNow: "",
      }
    }
  },
  computed: {
    countTitle() {
      let obj = {
        1: "进货数量",
        2: "销货数量",
        3: "损耗数量"
      }
      return obj[this.inventoryParams.inventoryType]
    }
  },
  created() {
    let merchantShopId = window.localStorage.getItem('playOne-store-merchantShopId');
    if (merchantShopId) {
      this.tableParams.merchantId = JSON.parse(merchantShopId)[0];
      this.tableParams.shopId = JSON.parse(merchantShopId)[1];
    }
  },
  mounted() {
    this.getMallGoodsList();// 【请求】表格数据
  },
  methods: {
    // 【请求】门店商品列表
    getMallGoodsList() {
      let data = this.tableParams;
      getMallGoodsList(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },

    // 【请求】新增门店商品列表
    getAddGoodsList() {
      let data = {
        merchantId: this.tableParams.merchantId,
        shopId: this.tableParams.shopId
      };
      getAddGoodsList(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.addGoodsList = res.data;
        }
      });
    },

    // 【请求】保存批量新增门店
    getAddGoodSave() {
      let data = {
        merchantId: this.tableParams.merchantId,
        shopId: this.tableParams.shopId,
        merchantGoodsIds: this.addParams.merchantGoodsIds
      };
      getAddGoodSave(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.$message({
            message: res.message,
            type: "success",
          });
          this.addShow = false;
          this.reload();// 【请求】表格数据
        }
      });
    },

    // 【请求】编辑新增商品
    getAddGoodEdit() {
      let data = this.params;
      getAddGoodEdit(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.$message({
            message: res.message,
            type: "success",
          });
          this.editShow = false;
          this.getMallGoodsList();// 【请求】表格数据
        }
      });
    },

    // 【请求】编辑商品进销损
    getInventoryEdit() {
      let data = this.inventoryParams;
      getInventoryEdit(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.$message({
            message: res.message,
            type: "success",
          });
          this.inventoryShow = false;
          this.getMallGoodsList();// 【请求】表格数据
        }
      });
    },

    // 【监听】表格模糊查询
    kwChange: _.debounce(function () {
      this.reload();
    }, 500),

    // 【监听】表格重载
    reload() {
      this.tableParams.page = 1;
      this.currentPage = 1;
      this.getMallGoodsList();
    },

    // 【监听】表格分页点击
    onCurrentPage(page) {
      this.tableParams.page = page;
      this.getMallGoodsList();
    },

    // 【监听】表格条数点击
    onSizeChange(pageSize) {
      this.tableParams.pageSize = pageSize;
      this.reload();
    },

    change() {
      this.$forceUpdate();
    },

    // 【监听】新增
    onAdd() {
      this.addShow = true;
      this.addParams.merchantGoodsIds = [];
      this.getAddGoodsList();
      this.$nextTick(() => {
        this.$refs["form-add"].clearValidate();
      });
    },

    // 【监听】编辑
    onEdit(row) {
      this.editShow = true;
      this.params = JSON.parse(JSON.stringify(row));
      this.$nextTick(() => {
        this.$refs["form-ref"].clearValidate();
      });
    },

    // 【监听】进损
    onInventory(row) {
      this.inventoryShow = true;
      this.inventoryParams = JSON.parse(JSON.stringify(row));
      this.inventoryParams.inventoryNow = row.inventory;
      this.inventoryParams.inventoryType = 1;
      this.inventoryParams.inventory = "";
      this.$nextTick(() => {
        this.$refs["form-inventory"].clearValidate();
      });
    },

    // 【监听】添加商品
    onAddSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.getAddGoodSave();
        } else {
          return false;
        }
      });
    },

    // 【监听】编辑商品
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.getAddGoodEdit();
        } else {
          return false;
        }
      });
    },

    // 【监听】进损商品
    onInventorySubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.getInventoryEdit();
        } else {
          return false;
        }
      });
    },

  }
}
</script>

<style lang="scss" scoped>
.mall-goods {
  padding: .15rem;
}

.table-footer {
  margin-top: .1rem;
  display: flex;
  justify-content: flex-end;
}

.table-search {
  display: flex;
  justify-content: space-between;
  margin-bottom: .15rem;
}

.search-L {
  display: flex;
}

.goods-ul {
  display: flex;
  flex-wrap: wrap;
}

.goods-li {
  margin: 0 .15rem .15rem 0;
  border: 1px solid #eee;
  position: relative;
}

.goods-checkbox {
  zoom: 150%;
  position: absolute;
  right: 0;
  top: 0;
}

.goods-p {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: .1rem;
  border-top: 1px solid #eee;
}

.form-footer {
  display: flex;
  justify-content: flex-end;
}

.el-checkbox-group {
  font-size: 14px;
}
</style>

<style lang="scss">
.goods-checkbox {
  .el-checkbox__label {
    display: none;
  }
}
</style>